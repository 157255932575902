<template>
  <div>
    <div class="accounting-templates__spinner-wrapper" v-if="loading">
      <app-spinner></app-spinner>
    </div>
    <v-row v-else>
      <v-col md="12" v-if="returnAbility('entry-template:store')">
        <v-btn color="green" depressed class="white--text mt-5 mr-5" rounded
          @click="$router.push('/createTemplateEntry')">
          <v-icon>mdi-plus</v-icon>
          {{ $t('add template') }}
        </v-btn>
      </v-col>
      <template v-for="template in templates">
        <v-col md="4" :key="template.id">
          <v-card elevation="3" class="mt-5 mx-5" width="320">
            <v-card-title class="justify-center">
              <h2>{{ template.name }}</h2>
            </v-card-title>
            <v-card-actions class="justify-space-between">
              <v-btn v-if="returnAbility('entry-template:update')" rounded depressed color="info" class="white--text"
                @click.prevent="
                  $router.push(`editTemplateEntry/${template.id}`)
                ">{{ $t('edit') }}</v-btn>
              <v-btn color="green" class="white--text" rounded @click="goToEntryAdd(template.id)">{{ $t('use')
              }}</v-btn>
              <app-delete-template v-if="returnAbility('entry-template:delete')" :template="template"
                @template-deleted="fetchTemplates"></app-delete-template>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import deleteTemplate from "./deleteTemplate.vue";
export default {
  components: {
    appDeleteTemplate: deleteTemplate,
  },
  data() {
    return {
      loading: false,
      templates: [],
    };
  },
  methods: {
    goToEntryAdd(id) {
      if (this.returnAbility("entry:store")) {
        this.$router.push(`/createEntryFromTemplate/${id}`);
      }
    },
    async fetchTemplates() {
      try {
        if (!this.returnAbility("entry-template:index")) return;
        this.loading = true;
        const templates = await axios.get("/accounting/entry-template");
        console.log("templates response", templates);
        this.templates = templates.data.data;
      } catch (err) {
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchTemplates();
  },
};
</script>

<style>
.accounting-templates__spinner-wrapper {
  width: 100%;
  height: 89vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>